import StringHelper from "../../global/StringHelper";
import sxQuery from "../../sxQuery/sxQuery";
import { ZoeApiConfigurationRegion } from "../interface/ZoeApiConfigurationRegion";
import UiHelper from "../ui/UiHelper";
class Zoe {
    constructor(region, apiKey, locale, title, context, name, image, identifier, suggest, dev = false) {
        this.isChatOpen = false;
        this.wasChatRendered = false;
        this.dev = false;
        this.region = region;
        this.apiKey = apiKey;
        this.locale = locale;
        this.name = name;
        this.identifier = identifier;
        this.suggest = suggest;
        this.context = context;
        this.title = title;
        this.dev = dev;
        this.image = image;
    }
    toggleChat() {
        if (this.identifier === undefined || this.suggest === undefined) {
            return false;
        }
        const $body = sxQuery(document.body);
        if (this.isChatOpen) {
            $body.find('.ss360-zoe-chat-backdrop').remove();
            $body.find('.ss360-zoe-chat').remove();
            this.isChatOpen = false;
            return false;
        }
        UiHelper.renderZoeScript(this.region === ZoeApiConfigurationRegion.BARRACUDA, this.dev);
        const chatBackdrop = sxQuery('<div role="presentation" class="ss360-zoe-chat-backdrop"></div>');
        const chatWrap = sxQuery('<div class="ss360-zoe-chat"></div>');
        const $closeButton = UiHelper.createCloseLayerButton('chat', 'Close chat');
        $closeButton.on('click', () => {
            this.toggleChat();
        });
        chatWrap.append($closeButton);
        const header = sxQuery('<header class="ss360-n-section ss360-zoe-chat__header"></header>');
        if (this.image) {
            header.append(`<img src="${this.image}" role="presentation" class="ss360-zoe-chat__image">`);
        }
        header.append(`<h2 class="ss360-zoe-chat__title">${this.title.replace('#PRODUCT_NAME#', StringHelper.escapeHtml(this.name))}</h2>`);
        chatWrap.append(header);
        const zoeChat = sxQuery(`<zoovu-zoe></zoovu-zoe>`);
        zoeChat.attr('mode', 'conversation');
        zoeChat.attr('sku', this.identifier);
        zoeChat.attr('region', this.region);
        zoeChat.attr('api-key', this.apiKey);
        zoeChat.attr('locale', this.locale);
        zoeChat.attr('autofocus', 'autofocus');
        if (this.dev) {
            zoeChat.attr('dev', 'qa10');
        }
        let contextString = '{}';
        try {
            contextString = JSON.stringify(this.context);
        }
        catch (err) {
            console.error(err);
        }
        zoeChat.attr('context', contextString);
        chatWrap.append(zoeChat);
        $body.append(chatBackdrop);
        chatBackdrop.on('click', () => {
            this.toggleChat();
        });
        $body.append(chatWrap);
        this.isChatOpen = true;
        this.wasChatRendered = true;
        return true;
    }
}
export default Zoe;
