'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import Handlers from './Handlers';
import Logger from '../../global/Logger';
import HiddenState from '../enums/HiddenState';
import Icons from '../components/Icons';
import LayoutType from '../enums/LayoutType';
import ListGridToggle from '../components/ListGridToggle';
import UiColors from '../styles/UiColors';
import DomElementBuilder from '../../sxQuery/DomElementBuilder';
const Ss360DocIcons = ['pdf.svg', 'xls.svg', 'javascript.svg', 'odp.svg', 'ods.svg', 'odt.svg', 'ppt.svg', 'doc.svg'];
const UiHelper = {
    showLoadingAnimation() {
        sxQuery('.ss360-loader').fadeIn();
    },
    hideLoadingAnimation() {
        sxQuery('.ss360-loader').fadeOut();
    },
    showError(message) {
        sxQuery('.ss360DevError').remove();
        const errorMessage = sxQuery(`<div class="ss360DevError" style="padding:10px;width:100%;position:fixed;bottom:0;left:0;background-color:${UiColors.RED};color:white;"><b>Site Search 360 Error:</b> 
			${message}</div>`);
        sxQuery('body').append(errorMessage);
    },
    addDarkenInputLayer(selectedSearchBox) {
        UiHelper.removeDarkenInputLayer();
        const darkBg = sxQuery('<div id="ss360Darken-input" class="ss360-darken"></div>');
        selectedSearchBox.parent().css('z-index', '999999');
        selectedSearchBox.parent().parent().append(darkBg);
    },
    removeDarkenInputLayer() {
        sxQuery('#ss360Darken-input').remove();
    },
    isDocIcon(imagePath) {
        return imagePath !== undefined && imagePath !== null ? Ss360DocIcons.indexOf(imagePath.replace('https://sitesearch360.com/cdn/', '').replace('https://cdn.sitesearch360.com/', '')) !== -1 : false;
    },
    getHiddenState(mobile, desktop) {
        if (!mobile && !desktop) {
            return HiddenState.All;
        }
        if (!mobile) {
            return HiddenState.Mobile;
        }
        if (!desktop) {
            return HiddenState.Desktop;
        }
        return HiddenState.None;
    },
    getHiddenParts(layoutConfiguration) {
        const { mobile, desktop } = layoutConfiguration;
        return {
            title: UiHelper.getHiddenState(mobile.showTitle, desktop.showTitle),
            url: UiHelper.getHiddenState(mobile.showUrl, desktop.showUrl),
            images: UiHelper.getHiddenState(mobile.showImages, desktop.showImages),
            snippet: UiHelper.getHiddenState(mobile.showSnippet, desktop.showSnippet),
            dataPoints: UiHelper.getHiddenState(mobile.showDataPoints, desktop.showDataPoints)
        };
    },
    updateLayerByHiddenParts(hiddenParts, layerContent) {
        if (layerContent === undefined) {
            return;
        }
        const toggle = (key, value) => {
            if (value === 'all') {
                return;
            }
            if (value === 'mobile') {
                layerContent.addClass(`ss360-hide-${key}--sm`);
            }
            if (value === 'desktop') {
                layerContent.addClass(`ss360-hide-${key}--lg`);
            }
        };
        toggle('title', hiddenParts.title);
        toggle('url', hiddenParts.url);
        toggle('images', hiddenParts.images);
        toggle('snippet', hiddenParts.snippet);
        toggle('dataPoints', hiddenParts.dataPoints);
    },
    getLogoSrc(allowCookies) {
        return allowCookies ? 'https://cdn.sitesearch360.com/ss360-logo.svg' : 'https://nc.sitesearch360.com/ss360-logo.svg';
    },
    highlightQueryTermsInResult(query, context, highlightContext = '.ss360-suggests__content > p, .ss360-suggests header a') {
        const parts = query.split(' ');
        for (let p = 0; p < parts.length; p++) {
            try {
                context.find(highlightContext).highlight(parts[p], 'ss360-highlight');
            }
            catch (e) {
                Logger.warn(e);
            }
        }
    },
    prepareLayoverLayer(layoutFrame, closeLayerHandler, pluginConfig) {
        const { results, filters, layout } = pluginConfig;
        layoutFrame.addClass('ss360-layer--overlay');
        const isWideOverlay = layout.navigation.position === 'left'
            || (filters.enabled && filters.position === 'left') || layout.desktop.type === LayoutType.Grid;
        if (isWideOverlay) {
            layoutFrame.addClass('ss360-layer--wide-overlay');
        }
        else {
            layoutFrame.removeClass('ss360-layer--wide-overlay');
        }
        if (!layoutFrame.isVisible()) {
            layoutFrame.show();
            layoutFrame.addClass('ss360-animated ss360-us');
            Handlers.layoverResize(layoutFrame);
            // prevent scrolling the whole when search results are focused by keyboard
            layoutFrame.off('scroll.ss360Scroll');
            layoutFrame.on('scroll.ss360Scroll', undefined, Handlers.layoverScroll);
        }
        layoutFrame.scrollTop(0);
        // darken background
        if (sxQuery('#ss360Darken').length === 0) {
            const darkBg = sxQuery(`<div id="ss360Darken" class="ss360-darken ss360-animated ss360-fid${results.hideLayerOnBodyClick ? '' : ' ss360-darken--noclose'}"></div>`);
            sxQuery('body').append(darkBg);
        }
        // add close layer button
        layoutFrame.addCloseLayerButton(closeLayerHandler);
        sxQuery('body').addClass('ss360-u-hide-y-overflow');
        const $window = sxQuery(window);
        $window.off('resize.ss360Resize');
        $window.on('resize.ss360Resize', () => {
            Handlers.layoverResize(layoutFrame);
        });
        Handlers.layoverResize(layoutFrame);
        setTimeout(() => {
            Handlers.layoverResize(layoutFrame);
        }, 100);
        setTimeout(() => {
            Handlers.layoverResize(layoutFrame);
        }, 1000);
        layoutFrame.off('focusout.layoverfocus');
        sxQuery(window).off('keydown.layoverfocus');
        let lastTabTs = -1;
        let isNextTab = true;
        sxQuery(window).on('keydown.layoverfocus', (e) => {
            if (e.which === 9) {
                lastTabTs = (new Date()).getTime();
                isNextTab = !e.shiftKey;
            }
        });
        layoutFrame.on('focusout.layoverfocus', (e) => {
            if (!layoutFrame.contains(e.relatedTarget)) {
                const tabTimeDiff = (new Date()).getTime() - lastTabTs;
                if (tabTimeDiff < 10) {
                    const els = layoutFrame.getFocusableElement();
                    const el = isNextTab ? els.get(0) : els.get(els.length - 1);
                    el.focus();
                }
            }
        });
    },
    hasVisibleContent(suggest, hiddenParts) {
        if (suggest instanceof Array) {
            suggest = suggest[0];
        }
        if (suggest.getType() === 'custom')
            return true;
        const name = suggest.getName();
        const image = suggest.getImage();
        const dataPoints = suggest.getDataPoints();
        const link = suggest.getLink();
        const content = suggest.getContent();
        const hasTitle = hiddenParts.title !== 'all' && name !== undefined && name.length > 0;
        if (hasTitle)
            return true;
        const hasSnippet = hiddenParts.snippet !== 'all' && content !== undefined && content.length > 0;
        if (hasSnippet)
            return true;
        const hasImage = hiddenParts.images !== 'all' && image !== null && image !== undefined && image.length > 0;
        if (hasImage)
            return true;
        const hasDataPoints = hiddenParts.dataPoints !== 'all' && (dataPoints || []).reduce((acc, dp) => acc || dp.show, false);
        if (hasDataPoints)
            return true;
        const hasUrl = hiddenParts.url !== 'all' && link !== null && link !== undefined && link.length > 0;
        return hasUrl;
    },
    createCloseLayerButton(modifier, title) {
        const modifierClassName = modifier ? ` ss360-close-button--${modifier}` : '';
        return sxQuery(`<button type="button" aria-label="${title}" title="${title}" type="button" class="ss360-close-button${modifierClassName} ss360-n-button">
			<i aria-hidden="true" role="presentation" class="ss360-close-button__icon">${Icons.getSvgIcon(Icons.CROSS, UiColors.DIM_GREY, 'ss360-close-button__icon-svg')}</i>
			<i aria-hidden="true" role="presentation" class="ss360-close-button__icon ss360-close-button__icon--active">${Icons.getSvgIcon(Icons.CROSS, UiColors.THEME, 'ss360-close-button__icon-svg')}</i>
			</button>`);
    },
    getSearchButtonIconColor(pluginConfig) {
        const hasLabel = pluginConfig.searchBox.searchButtonLabel !== undefined;
        let searchButtonIconColor = UiColors.WHITE;
        if (!hasLabel && pluginConfig.style.searchBox !== undefined) { // we need to check if the background was modified, otherwise we could end up with white on white
            const searchBoxStyle = pluginConfig.style.searchBox;
            if (searchBoxStyle.background !== undefined && searchBoxStyle.background.color !== undefined) {
                if (searchBoxStyle.button !== undefined && searchBoxStyle.button.textColor !== undefined) {
                    searchButtonIconColor = searchBoxStyle.button.textColor;
                }
                else if (searchBoxStyle.text !== undefined && searchBoxStyle.text.color !== undefined) {
                    searchButtonIconColor = searchBoxStyle.text.color;
                }
            }
        }
        return searchButtonIconColor;
    },
    getGridClassName(layoutConfig, contentGroup, context) {
        const gridClasses = [];
        const mobileType = layoutConfig.mobile.type;
        const desktopType = layoutConfig.desktop.type;
        let isGridMobile = (mobileType === LayoutType.Mixed && (layoutConfig.mobile.gridContentGroups || []).indexOf(contentGroup) !== -1) || mobileType === LayoutType.Grid;
        let isGridDesktop = (desktopType === LayoutType.Mixed && (layoutConfig.desktop.gridContentGroups || []).indexOf(contentGroup) !== -1) || desktopType === LayoutType.Grid;
        if (layoutConfig.showListGridToggle && (isGridMobile || isGridDesktop) && context.readCookie(ListGridToggle.COOKIE_NAME) === 'list') {
            isGridDesktop = false;
            isGridMobile = false;
        }
        if (isGridMobile || isGridDesktop) {
            gridClasses.push('ss360-grid');
            if (isGridMobile) {
                gridClasses.push('ss360-grid--sm');
            }
            if (isGridDesktop) {
                gridClasses.push('ss360-grid--lg');
            }
            if (layoutConfig.singleLineGridTitle) {
                gridClasses.push('ss360-grid--singleline-title');
            }
        }
        const isMasonryMobile = mobileType === LayoutType.Masonry;
        const isMasonryDesktop = desktopType === LayoutType.Masonry;
        if (isMasonryDesktop || isMasonryMobile) {
            gridClasses.push('ss360-masonry');
            if (isMasonryDesktop) {
                gridClasses.push('ss360-masonry--lg');
            }
            if (isMasonryMobile) {
                gridClasses.push('ss360-masonry--sm');
            }
        }
        return gridClasses.length === 0 ? '' : ` ${gridClasses.join(' ')}`;
    },
    prependCloseButton(target, callback) {
        const $btn = UiHelper.createCloseLayerButton('layer', 'Close Search Results');
        if (callback !== undefined) {
            $btn.on('click', callback);
        }
        target.prepend($btn);
    },
    getResultCount(key, values, showAllResultsTab, searchResultConfig, data, totalResults) {
        const limit = searchResultConfig.num;
        if (showAllResultsTab && key === '*') {
            const keyset = data.getResultGroupNames().filter((key) => key !== '*');
            const total = keyset.reduce((acc, groupName) => acc + UiHelper.getResultCount(groupName, data.getSuggestsArray(groupName), showAllResultsTab, searchResultConfig, data, totalResults), data.additionalResults);
            return Math.max(total, (data.getSuggestsArray('*') || []).length);
        }
        let resultsAvailable = values.length;
        if (data.totalResultsPerContentGroup !== undefined && data.totalResultsPerContentGroup[key] !== undefined) {
            resultsAvailable = data.totalResultsPerContentGroup[key];
        }
        if (!searchResultConfig.group || Object.keys(data.plain.suggests).length === 1) {
            resultsAvailable = totalResults;
        }
        if (resultsAvailable === 0 && values.length > 0) {
            resultsAvailable = values.length;
        }
        resultsAvailable = resultsAvailable || totalResults;
        return Math.min(resultsAvailable, limit);
    },
    recreateNode($node) {
        try {
            const $parentParent = $node.parent();
            $node.html('');
            const parentNode = $node.get(0);
            const nextElementSibling = parentNode.nextElementSibling;
            const outerHtml = parentNode.outerHTML;
            $node.remove();
            if (nextElementSibling === undefined || nextElementSibling === null) {
                $parentParent.append(outerHtml);
            }
            else {
                $parentParent.get(0).insertBefore(sxQuery(outerHtml).get(0), nextElementSibling);
            }
        }
        catch (err) {
            Logger.error(err);
        }
    },
    renderZoeScript(isBarracuda = false, isDev = false) {
        const id = 'zoovu-zoe-script';
        if (document.getElementById(id) !== null) {
            return;
        }
        const s = document.createElement('script');
        s.setAttribute('id', id);
        if (!isDev) {
            const scriptBase = isBarracuda ? 'https://barracuda-cdn.zoovu.com' : 'https://orca-cdn.zoovu.com';
            s.setAttribute('src', `${scriptBase}/core-js/zoovu-tag-library/zoovu-tag-library-latest.min.js`);
        }
        else {
            s.setAttribute('src', 'https://assets-staging-runner.zoovu.com/core-js/zoovu-tag-library/zoovu-tag-library-latest.min.js');
        }
        sxQuery('body').append(sxQuery(s));
    },
    renderRating(ratingDataPoint, ratingCountDataPoint, ratingCountLabel, maxRating, classPrefix, includeMeta = true, tag = 'div') {
        if (ratingDataPoint === undefined || ratingDataPoint.parsedValue === undefined) {
            return undefined;
        }
        const rating = ratingDataPoint.parsedValue;
        const ratingStars = (rating / maxRating) * 5;
        const ratingRest = ratingStars % 1;
        let fullIcons = Math.floor(ratingStars);
        if (ratingRest >= 0.75) {
            fullIcons++;
        }
        const halfIcons = ratingRest > 0.25 && ratingRest < 0.75 ? 1 : 0;
        const emptyIcons = 5 - fullIcons - halfIcons;
        const iconsWrap = DomElementBuilder.createElement({
            tag: 'span',
            classList: [`${classPrefix}__rating-stars`],
            children: [
                ...Array.from({ length: fullIcons }, () => Icons.getSvgIconNode(Icons.STAR, UiColors.YELLOW, undefined, 20, 19, '0 0 20 19')),
                ...Array.from({ length: halfIcons }, () => sxQuery(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><g clip-path="url(#a)"><path d="m10 15.047-4.279 3.11c-.9.653-2.111-.227-1.767-1.285l1.634-5.03-4.279-3.109c-.9-.654-.437-2.078.675-2.078h5.29l1.634-5.03c.344-1.058 1.84-1.058 2.185 0l1.634 5.03h5.289c1.113 0 1.575 1.424.675 2.078l-4.279 3.109 1.635 5.03c.344 1.058-.868 1.938-1.768 1.284L10 15.048Z" fill="${UiColors.YELLOW}"/><mask id="b" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="0" width="10" height="20"><path fill="${UiColors.GREY_TEXT}" d="M10 0h10v20H10z"/></mask><g mask="url(#b)"><path d="m10 15.047-4.279 3.11c-.9.653-2.111-.227-1.767-1.285l1.634-5.03-4.279-3.109c-.9-.654-.437-2.078.675-2.078h5.29l1.634-5.03c.344-1.058 1.84-1.058 2.185 0l1.634 5.03h5.289c1.113 0 1.575 1.424.675 2.078l-4.279 3.109 1.635 5.03c.344 1.058-.868 1.938-1.768 1.284L10 15.048Z" fill="${UiColors.GREY_TEXT}"/></g></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs></svg>`).get(0)),
                ...Array.from({ length: emptyIcons }, () => Icons.getSvgIconNode(Icons.STAR, UiColors.GREY_TEXT, undefined, 20, 19, '0 0 20 19'))
            ]
        });
        let countWrap;
        if (ratingCountDataPoint !== undefined) {
            countWrap = DomElementBuilder.createElement({
                tag: 'span',
                classList: [`${classPrefix}__rating-count`],
                textContent: ratingCountLabel.replace(/#COUNT#/g, ratingCountDataPoint.parsedValue ? Math.floor(ratingCountDataPoint.parsedValue).toFixed(0) : ratingCountDataPoint.value)
            });
        }
        const baseAttrs = {};
        const isBtn = tag === 'button';
        if (isBtn) {
            baseAttrs.type = 'button';
        }
        return DomElementBuilder.createElement({
            tag,
            classList: [`${classPrefix}__rating`, isBtn ? 'ss360-n-button' : undefined, isBtn ? `${classPrefix}__rating--btn` : undefined],
            attrs: includeMeta ? Object.assign(Object.assign({}, baseAttrs), { itemprop: 'aggregateRating', itemscope: 'itemscope', itemtype: 'http://schema.org/AggregateRating' }) : baseAttrs,
            children: [
                iconsWrap,
                countWrap,
                ...(includeMeta ? [
                    DomElementBuilder.createElement({ tag: 'meta', attrs: { itemprop: 'ratingValue', content: rating.toString() } }),
                    DomElementBuilder.createElement({ tag: 'meta', attrs: { itemprop: 'bestRating', content: '5' } }),
                    DomElementBuilder.createElement({ tag: 'meta', attrs: { itemprop: 'worstRating', content: '0' } }),
                    ratingCountDataPoint ? DomElementBuilder.createElement({ tag: 'meta', attrs: { itemprop: 'ratingCount', content: ratingCountDataPoint.value } }) : undefined
                ] : [])
            ]
        });
    }
};
export default UiHelper;
