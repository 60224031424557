import StringHelper from "../../global/StringHelper";
import MediaQueryMatchType from "../../sxQuery/MediaQueryMatchType";
import sxQuery from "../../sxQuery/sxQuery";
import SxQueryObject from "../../sxQuery/SxQueryObject";
import SxQueryUtils from "../../sxQuery/SxQueryUtils";
import SearchResultType from "../enums/SearchResultType";
import { ZoeApiConfigurationRegion } from "../interface/ZoeApiConfigurationRegion";
import SearchSuggest from "../model/SearchSuggest";
import UiColors from "../styles/UiColors";
import UiHelper from "../ui/UiHelper";
import Helper from "../utils/Helper";
import NavigatorHelper from "../utils/NavigatorHelper";
import Icons from "./Icons";
import Score from "./Score";
const formatPDPPrice = (price, priceUnit) => {
    if (priceUnit === undefined || priceUnit === null || priceUnit.trim().length === 0 || price.indexOf(priceUnit) !== -1) {
        return price;
    }
    if (priceUnit.trim() === '$' || priceUnit.trim().toLowerCase() === 'usd') {
        return `${priceUnit}${price}`;
    }
    return `${price} ${priceUnit}`;
};
let lastOpenMiniPDP = undefined;
class MiniPDP {
    constructor(context, suggest, suggestItem, query, breadcrumb) {
        this.logAbandon = true;
        this.isImageExpanded = false;
        this.breadcrumb = [];
        this.context = context;
        this.suggest = suggest;
        this.suggestItem = suggestItem;
        this.query = query;
        context.subscribe('close-mini-pdp', 'mini-pdp', () => {
            this.close();
        });
        this.breadcrumb = breadcrumb || [];
        this.queryParamName = context.getMiniPDPQueryParamName();
    }
    show(expandRelations = false, preventTransition = false, preventState = false, expandReviews = false) {
        sxQuery(window).off(`beforeunload.${this.context.getInsightsEventKey()}`);
        const onCloseClick = () => {
            if (this.isImageExpanded) {
                this.isImageExpanded = false;
                this.wrap.find('.ss360-mini-pdp__image-wrap').removeClass('ss360-mini-pdp__image-wrap--expanded');
                const $img = this.wrap.find('.ss360-mini-pdp__image');
                $img.css('height', null);
                $img.css('width', null);
                SxQueryUtils.requestAnimationFrame(() => {
                    $img.css('height', Math.ceil($img.outerHeight()));
                    $img.css('width', Math.ceil($img.outerWidth()));
                });
            }
            else {
                this.close();
            }
        };
        sxQuery('body').on('keydown.miniPDP', (e) => {
            if (e.keyCode === 27 || e.which === 27 || e.key === 'Escape' || e.code === 'Escape') {
                e.stopPropagation();
                onCloseClick();
            }
        });
        this.suggestItem.find('.ss360-suggests__wrap').addClass('ss360-suggests__wrap--mini-pdp-active');
        this.suggestItem.addClass('ss360-suggests--mini-pdp-active');
        if (lastOpenMiniPDP !== undefined) {
            lastOpenMiniPDP.close(false);
        }
        this.context.emitEvent('select-product', {
            product: this.suggest.suggest
        });
        lastOpenMiniPDP = this;
        const suggest = this.suggest;
        const pluginConfiguration = this.context.pluginConfiguration;
        const similarContentConfiguration = pluginConfiguration.similarContent;
        const resultsConfiguration = pluginConfiguration.results;
        const configuration = pluginConfiguration.miniPDP;
        const section = sxQuery(`<article class="ss360-mini-pdp ss360-n-section" itemtype="http://schema.org/Product" itemscope><meta itemprop="sku" content="${suggest.getIdentifier()}"></article>`);
        if (configuration.floating) {
            section.addClass('ss360-mini-pdp--floating');
        }
        else if (configuration.fullscreen) {
            section.addClass('ss360-mini-pdp--fullscreen');
            section.css('transform', `matrix(1, 0, 0, 1, ${sxQuery('body').outerWidth()}, 0)`);
        }
        if (preventTransition) {
            section.addClass('ss360-mini-pdp--no-transition');
        }
        if (suggest.getLink()) {
            section.append(`<meta itemprop="url" content="${suggest.getLink()}">`);
        }
        const $header = sxQuery(`<div class="ss360-mini-pdp__header"><h2 class="ss360-mini-pdp__heading" itemprop="name">${StringHelper.escapeHtml(suggest.getName() || configuration.title)}</div></div>`);
        const $closeButton = UiHelper.createCloseLayerButton('mini-pdp', 'Close');
        $closeButton.addClass('ss360-mini-pdp__close');
        $closeButton.on('click', () => {
            onCloseClick();
        });
        if (configuration.floating) {
            const $ctaCloseWrap = sxQuery('<div class="ss360-mini-pdp__cta-close-wrap"></div>');
            const $ctas = this._renderCtas();
            $ctas.addClass('ss360-mini-pdp__cta-wrap--lg');
            $ctaCloseWrap.append($ctas);
            $ctaCloseWrap.append($closeButton);
            $header.append($ctaCloseWrap);
        }
        else {
            $header.append($closeButton);
        }
        const headerImageWrap = sxQuery('<div class="ss360-mini-pdp__header-image-wrap"></div>');
        const $breadcrumb = this._renderBreadcrumb();
        if ($breadcrumb !== undefined) {
            const wrap = sxQuery('<div class="ss360-mini-pdp__breadcrumb-title-wrap"></div>');
            $header.prepend(wrap);
            wrap.append($breadcrumb);
            wrap.append($header.find('.ss360-mini-pdp__heading'));
        }
        headerImageWrap.append($header);
        if (suggest.getImage() && !configuration.floating) {
            headerImageWrap.append(this._renderImage());
        }
        section.append(headerImageWrap);
        const $content = sxQuery('<div class="ss360-mini-pdp__content"></div>');
        const $tabContent = sxQuery('<div class="ss360-mini-pdp__tab-content"></div>');
        const tabs = [];
        const focusTab = (type) => {
            $tabContent.find('.ss360-mini-pdp__tab-content').addClass('ss360-mini-pdp__tab-content--hidden');
            $tabContent.find(`.ss360-mini-pdp__tab-content--${type}`).removeClass('ss360-mini-pdp__tab-content--hidden');
            $tabs.find('.ss360-mini-pdp__tab').attr('aria-selected', 'false');
            $tabs.find(`.ss360-mini-pdp__tab--${type}`).attr('aria-selected', 'true');
            $tabs.find(`.ss360-mini-pdp__tab`).removeClass('ss360-mini-pdp__tab--active');
            $tabs.find(`.ss360-mini-pdp__tab--${type}`).addClass('ss360-mini-pdp__tab--active');
        };
        const renderRating = (rating, ratingCount, maxRating) => {
            const rcValue = (ratingCount !== undefined ? ratingCount.parsedValue : 0);
            const ratingWrapper = UiHelper.renderRating(rating, ratingCount, rcValue === undefined || rcValue > 1 ? pluginConfiguration.results.ratingCountLabel : pluginConfiguration.results.ratingCountLabelSingular, maxRating || pluginConfiguration.results.maxRating, 'ss360-mini-pdp', true, 'button');
            if (ratingWrapper !== undefined) {
                $content.find('.ss360-mini-pdp__rating').remove();
                $content.prepend(ratingWrapper);
                const scrollToReviews = () => {
                    const node = section.find('.ss360-mini-pdp__review').get(0);
                    if (node) {
                        node.scrollIntoView({ block: 'start', behavior: 'smooth' });
                    }
                };
                sxQuery(ratingWrapper).on('click', () => {
                    focusTab('reviews');
                    scrollToReviews();
                });
                if (expandReviews) {
                    scrollToReviews();
                }
            }
        };
        const suggestRatingDataPoint = suggest.getRatingDataPoint();
        if (pluginConfiguration.results.ratingDataPoint !== undefined || suggestRatingDataPoint !== undefined) {
            const rating = suggestRatingDataPoint || Helper.getDataPoint(suggest, pluginConfiguration.results.ratingDataPoint);
            const ratingCount = suggest.getRatingCountDataPoint() || (pluginConfiguration.results.ratingCountDataPoint !== undefined ? Helper.getDataPoint(suggest, pluginConfiguration.results.ratingCountDataPoint) : undefined);
            renderRating(rating, ratingCount);
        }
        const $priceContainer = sxQuery('<div class="ss360-mini-pdp__price-container ss360-shimmer" aria-busy="true"></div>');
        $content.append($priceContainer);
        this.priceDataPoint = Helper.getDataPoint(suggest, resultsConfiguration.priceDataPoint, 'newPrice');
        let oldPriceDataPoint = Helper.getDataPoint(suggest, resultsConfiguration.oldPriceDataPoint, 'oldPrice');
        if (this.priceDataPoint) {
            this._fillPriceContainer($priceContainer, this.priceDataPoint, oldPriceDataPoint);
        }
        const $ctas = this._renderCtas();
        if (configuration.floating) {
            $ctas.addClass('ss360-mini-pdp__cta-wrap--sm');
        }
        $content.append($ctas);
        const hasVariants = suggest.getUniqueVariantImageCount(this.context.pluginConfiguration.results.variantGroupingAttribute) > 1;
        if (hasVariants && !configuration.floating) {
            $content.append(this._renderVariants());
        }
        const $tabsNav = sxQuery('<nav class="ss360-mini-pdp__tabs-nav ss360-n-section" role="navigation"></nav>');
        const $tabs = sxQuery('<div class="ss360-mini-pdp__tabs" role="menubar"></div>');
        $tabsNav.append($tabs);
        const createTab = (type, label) => {
            const $tabBtn = sxQuery(`<button class="ss360-n-button ss360-mini-pdp__tab ss360-mini-pdp__tab--${type}" type="button" role="menuitem"></button>`);
            $tabBtn.text(label);
            $tabBtn.on('click', () => {
                focusTab(type);
            });
            $tabs.append($tabBtn);
            tabs.push(type);
            return $tabBtn;
        };
        const createTabContent = (type, label) => {
            const $tab = sxQuery(`<section class="ss360-mini-pdp__tab-content ss360-mini-pdp__tab-content--hidden ss360-mini-pdp__tab-content--${type} ss360-n-section"></section>`);
            $tab.attr('aria-label', label);
            $tabContent.append($tab);
            return $tab;
        };
        const removeTab = (type) => {
            tabs.splice(tabs.indexOf(type), 1);
            $tabs.find(`.ss360-mini-pdp__tab--${type}`).remove();
            const $currentTab = $tabContent.find(`.ss360-mini-pdp__tab-content--${type}`);
            const isActive = !$currentTab.hasClass('ss360-mini-pdp__tab-content--hidden');
            $currentTab.remove();
            if ($tabs.children().length <= 1) {
                $tabsNav.remove();
            }
            if ($tabContent.children().length === 0) {
                $tabContent.remove();
            }
            else if (($tabContent.children().length === 1 || isActive) && tabs[0]) {
                focusTab(tabs[0]);
            }
        };
        let $picturesTabContent;
        if (suggest.getImage() && configuration.floating) {
            createTab('pictures', configuration.picturesTab);
            $picturesTabContent = createTabContent('pictures', configuration.picturesTab);
            if (hasVariants) {
                $picturesTabContent.append(this._renderVariants());
            }
            $picturesTabContent.append(this._renderImage());
            const $bulletPoints = this._renderBulletPoints([]);
            if ($bulletPoints !== undefined) {
                $picturesTabContent.append($bulletPoints);
            }
        }
        let hasContent = false;
        createTab('details', configuration.detailsTab);
        const $contentTabContent = createTabContent('details', configuration.detailsTab);
        if (pluginConfiguration.results.scoreDataPoints.length > 0) {
            const score = Score.createScore(suggest, pluginConfiguration.results.scoreDataPoints);
            if (score !== undefined) {
                const $score = sxQuery(score);
                $score.addClass('ss360-mini-pdp__score');
                $contentTabContent.append($score);
                hasContent = true;
            }
        }
        if (pluginConfiguration.zoe.apiKey !== undefined) {
            const $zoeWrap = this._renderZoe();
            $contentTabContent.append($zoeWrap);
            hasContent = true;
        }
        const $attributes = sxQuery('<div class="ss360-mini-pdp__attributes"></div>');
        $attributes.append(`<h4 class="ss360-mini-pdp__attributes-heading ss360-sr-only">${StringHelper.escapeHtml(configuration.attributesTitle)}</h4>`);
        const $attributeList = sxQuery('<ul class="ss360-mini-pdp__attribute-list ss360-mini-pdp__attribute-list--loading ss360-shimmer" aria-busy="true"></ul>');
        $attributes.append($attributeList);
        $contentTabContent.append($attributes);
        const includeTag = similarContentConfiguration.active ? similarContentConfiguration.includeTag : undefined;
        const includeRelation = similarContentConfiguration.active ? similarContentConfiguration.includeRelation : undefined;
        let $relationContainer = includeTag || includeRelation ? sxQuery('<section class="ss360-mini-pdp__relations ss360-mini-pdp__relations--loading ss360-shimmer ss360-n-section" aria-busy="true"></section>') : new SxQueryObject([]);
        $relationContainer.attr('aria-label', similarContentConfiguration.title);
        if (includeTag || includeRelation) {
            createTab('relations', similarContentConfiguration.title);
            createTabContent('relations', similarContentConfiguration.title).append($relationContainer);
        }
        createTab('reviews', configuration.reviewsTab);
        const $reviewsTabContent = createTabContent('reviews', configuration.reviewsTab);
        const loadStartTime = (new Date()).getTime();
        SxQueryUtils.get(this.context.urlBuilder.buildMiniPdpUrl(suggest.getIdentifier(), includeTag, includeRelation), (response) => {
            if (this.priceDataPoint === undefined && response.newPrice !== undefined && response.newPrice !== null) {
                this.priceDataPoint = {
                    value: formatPDPPrice(response.newPrice, response.newPriceUnit),
                    unit: response.newPriceUnit,
                    key: 'newPrice',
                    show: false
                };
                oldPriceDataPoint = response.oldPrice !== undefined && response.oldPrice !== null && response.oldPrice !== response.newPrice ? {
                    value: formatPDPPrice(response.oldPrice, response.oldPriceUnit),
                    unit: response.oldPriceUnit,
                    key: 'oldPrice',
                    show: false
                } : undefined;
                this._fillPriceContainer($priceContainer, this.priceDataPoint, oldPriceDataPoint);
            }
            else if (this.priceDataPoint === undefined) {
                this._fillPriceContainer($priceContainer, undefined, undefined);
            }
            if (response.attributes === undefined || response.attributes.length === 0) {
                $attributes.remove();
                if (!hasContent) {
                    removeTab('details');
                }
            }
            else {
                $attributeList.removeClass('ss360-mini-pdp__attribute-list--loading ss360-shimmer');
                $attributeList.attr('aria-busy', 'false');
                response.attributes.forEach((attribute) => {
                    let value = attribute.value;
                    if (attribute.unit) {
                        const space = attribute.unit === '"' || attribute.unit === '€' || attribute.unit === '$' ? '' : ' ';
                        value = `${value}${space}${attribute.unit}`;
                    }
                    $attributeList.append(`<li class="ss360-mini-pdp__attribute"><span class="ss360-mini-pdp__attribute-key">${StringHelper.escapeHtml(attribute.attribute)}</span> <span class="ss360-mini-pdp__attribute-value">${StringHelper.escapeHtml(value)}</span></li>`);
                });
            }
            const relationResults = [];
            if (response.relations !== undefined && response.relations.tags !== undefined) {
                response.relations.tags.forEach((tag) => {
                    if (tag.relations !== undefined) {
                        tag.relations.forEach((rel) => {
                            (rel.searchResult || []).forEach((result) => {
                                relationResults.push(result);
                            });
                        });
                    }
                });
            }
            if (relationResults.length > 0) {
                this._renderRelations(relationResults, $relationContainer, loadStartTime, includeTag, includeRelation);
                if (expandRelations) {
                    section.find('.ss360-mini-pdp__relation-list').get(0).scrollIntoView({ block: 'start', behavior: 'smooth' });
                }
            }
            else {
                $relationContainer.remove();
                removeTab('relations');
            }
            if (response.reviews !== undefined && response.reviews.length > 0) {
                this._renderReviews(response.reviews, $reviewsTabContent);
                renderRating({
                    key: 'rating',
                    value: '',
                    show: false,
                    parsedValue: response.avgRating
                }, {
                    key: 'numReviews',
                    value: '',
                    show: false,
                    parsedValue: response.numReviews
                }, 5);
            }
            else {
                removeTab('reviews');
                $reviewsTabContent.remove();
            }
            if ($picturesTabContent !== undefined) {
                const $bulletPoints = this._renderBulletPoints(response.attributes);
                if ($bulletPoints !== undefined) {
                    $picturesTabContent.find('.ss360-mini-pdp__bullet-points').remove();
                    $picturesTabContent.append($bulletPoints);
                }
            }
            if (configuration.renderCallback !== undefined) {
                configuration.renderCallback(this.suggest, $content, response);
            }
        }, (error) => {
            console.error(error);
            if (this.priceDataPoint === undefined) {
                this._fillPriceContainer($priceContainer, this.priceDataPoint, oldPriceDataPoint);
            }
            $attributes.remove();
            $relationContainer.remove();
            if (configuration.renderCallback !== undefined) {
                configuration.renderCallback(this.suggest, $content, undefined);
            }
        });
        if (!expandRelations && !expandReviews) {
            focusTab(suggest.getImage() && configuration.floating ? 'pictures' : 'details');
        }
        else if (expandRelations) {
            focusTab('relations');
        }
        else if (expandReviews) {
            focusTab('reviews');
        }
        $content.append($tabsNav);
        $content.append($tabContent);
        section.append($content);
        if (configuration.showBackdrop) {
            const $backdrop = sxQuery('<div class="ss360-mini-pdp__backdrop" role="presentation"></div>');
            $backdrop.on('click', () => {
                this.close();
            });
            this.context.layer.get().appendToMiniPDPFrame($backdrop);
            this.backdrop = $backdrop;
        }
        this.context.layer.get().appendToMiniPDPFrame(section);
        SxQueryUtils.requestAnimationFrame(() => {
            sxQuery('body').addClass('ss360-u-hide-y-overflow');
            section.addClass('ss360-mini-pdp--open');
            if (configuration.fullscreen) {
                section.css('transform', 'translateX(0)');
            }
        });
        this.wrap = section;
        if (!preventState) {
            const queryDict = NavigatorHelper.buildQueryDict();
            queryDict[this.queryParamName] = suggest.getIdentifier();
            NavigatorHelper.pushState(queryDict, this.context.pluginConfiguration, undefined, {
                type: 'mini-pdp',
                suggest: suggest.plain,
                breadcrumb: (this.breadcrumb || []).map((b) => ({
                    name: b.name,
                    suggest: b.suggest.plain
                })),
                query: this.query
            });
        }
        if (configuration.floating) {
            let isComputing = false;
            const reposition = () => {
                if (isComputing) {
                    return;
                }
                isComputing = true;
                SxQueryUtils.requestAnimationFrame(() => {
                    let positionValid = false;
                    if (SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Min, 768)) {
                        const clientWidth = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
                        const w = Math.min(900, 0.6 * clientWidth);
                        const firstResult = this.context.layer.get().getVisibleResults().get(0);
                        if (firstResult !== undefined) {
                            const firstResultRect = firstResult.getBoundingClientRect();
                            const l = firstResultRect.left + 64;
                            if (l + w < clientWidth) {
                                this.wrap.css('left', `${l}px`);
                                positionValid = true;
                            }
                        }
                    }
                    if (!positionValid) {
                        this.wrap.css('left', null);
                    }
                    isComputing = false;
                });
            };
            reposition();
            sxQuery(window).off(`resize.miniPDP, orientationchange.miniPDP`).on(`resize.miniPDP, orientationchange.miniPDP`, reposition);
        }
    }
    _renderImage() {
        const suggest = this.suggest;
        const configuration = this.context.pluginConfiguration.miniPDP;
        let imgSrc = suggest.getImage(true);
        const $img = sxQuery(`<img src="${imgSrc}" alt="" itemprop="image" class="ss360-mini-pdp__image ss360-shimmer" loading="lazy">`);
        $img.on('load', () => {
            $img.removeClass('ss360-shimmer');
            setTimeout(() => {
                $img.css('height', Math.ceil($img.outerHeight()));
                $img.css('width', Math.ceil($img.outerWidth()));
            }, 0);
        });
        const $imgWrap = sxQuery('<div class="ss360-mini-pdp__image-wrap"></div>');
        $img.on('click', () => {
            if (this.isImageExpanded) {
                return;
            }
            this.isImageExpanded = true;
            $imgWrap.addClass('ss360-mini-pdp__image-wrap--expanded');
            $img.css('height', null);
            $img.css('width', null);
            SxQueryUtils.requestAnimationFrame(() => {
                $img.css('height', Math.ceil($img.outerHeight()));
                $img.css('width', Math.ceil($img.outerWidth()));
            });
        });
        const $imgPaginationWrap = sxQuery('<div class="ss360-mini-pdp__image-pagination-wrap"></div>');
        $imgPaginationWrap.append($img);
        const images = suggest.getImages();
        let setImageTimeout;
        $imgWrap.append($imgPaginationWrap);
        if (images.length > 1) {
            if (images.length > configuration.maxImages) {
                images.splice(configuration.maxImages);
            }
            const imageNodeArray = [];
            let activeImage = 0;
            const createPaginationArrow = (type) => {
                const $btn = sxQuery(`<button class="ss360-n-button ss360-mini-pdp__image-pagination-button ss360-mini-pdp__image-pagination-button--${type}" type="button"></button>`);
                $btn.append(Icons.getSvgIconNode(Icons.SIMPLE_ARROW, UiColors.GREY_TEXT, `ss360-mini-pdp__image-pagination-button-icon ss360-mini-pdp__image-pagination-button-icon--${type}`, 32, 32, '0 0 24 24'));
                return $btn;
            };
            const setImage = (src, activeIndex = -1) => {
                clearTimeout(setImageTimeout);
                setImageTimeout = setTimeout(() => {
                    $img.attr('src', src);
                }, 16);
                if (activeIndex !== -1) {
                    imageNodeArray.forEach(($img, idx) => {
                        if (idx === activeIndex) {
                            $img.addClass('ss360-mini-pdp__image-list-item--active');
                        }
                        else {
                            $img.removeClass('ss360-mini-pdp__image-list-item--active');
                        }
                    });
                    activeImage = activeIndex;
                }
            };
            const $prev = createPaginationArrow('prev');
            $prev.on('click', () => {
                activeImage = (activeImage - 1 + images.length) % images.length;
                setImage(images[activeImage], activeImage);
            });
            $imgPaginationWrap.prepend($prev);
            const $next = createPaginationArrow('next');
            $next.on('click', () => {
                activeImage = (activeImage + 1) % images.length;
                setImage(images[activeImage], activeImage);
            });
            $imgPaginationWrap.append($next);
            const imageList = sxQuery('<div class="ss360-mini-pdp__image-list"></div>');
            for (let i = 0; i < images.length; i++) {
                const $listImage = sxQuery(`<img src="${images[i]}" alt="" class="ss360-mini-pdp__image-list-item" loading="lazy">`);
                if (i === 0) {
                    $listImage.addClass('ss360-mini-pdp__image-list-item--active');
                }
                $listImage.on('click', () => {
                    setImage(images[i], i);
                });
                imageList.append(sxQuery($listImage));
                imageNodeArray.push($listImage);
            }
            $imgWrap.append(imageList);
        }
        else {
            $imgWrap.addClass('ss360-mini-pdp__image-wrap--single');
        }
        return $imgWrap;
    }
    _renderCtas() {
        const pluginConfiguration = this.context.pluginConfiguration;
        const resultsConfiguration = pluginConfiguration.results;
        const addToCartLink = resultsConfiguration.addToCartLink;
        const addToCartCallback = pluginConfiguration.callbacks.addToCart;
        const $ctaWrap = sxQuery('<div class="ss360-mini-pdp__cta-wrap"></div>');
        const suggest = this.suggest;
        let hasAddToCart = false;
        if (!pluginConfiguration.miniPDP.floating && (addToCartLink || addToCartCallback)) {
            const $addToCart = sxQuery('<button class="ss360-n-button ss360-mini-pdp__cta ss360-cta ss360-cta--button ss360-cta--primary" type="button"></button>');
            $addToCart.append(Icons.getSvgIconNode(Icons.SHOPPING_CART, UiColors.WHITE, 'ss360-mini-pdp__cta-icon ss360-cta__icon', undefined, undefined, '0 0 17 16'));
            $addToCart.append(`<span>${StringHelper.escapeHtml(resultsConfiguration.addToCartLabel)}</span>`);
            $addToCart.on('click', () => {
                this.logAbandon = false;
                this.context.emitEvent('add-to-cart', {
                    product: suggest.suggest
                });
                if (this.context.getInsights()) {
                    let price;
                    let priceUnit;
                    if (this.priceDataPoint !== undefined) {
                        const priceData = Helper.getPriceAndCurrency(this.priceDataPoint);
                        price = priceData.price;
                        priceUnit = priceData.currency;
                    }
                    this.context.getInsights().trackAddToCart(suggest.getIdentifier(), suggest.getIdentifier(), suggest.getLink(), 1, price, priceUnit, true);
                }
                if (addToCartCallback !== undefined && addToCartCallback !== null) {
                    addToCartCallback(suggest.getIdentifier(), (suggest.plain instanceof Array ? suggest.plain[0] : suggest.plain));
                }
                else {
                    window.location.href = addToCartLink.replace(/#RESULT_LINK#/g, suggest.getIdentifier()).replace(/#IDENTIFIER#/g, suggest.getIdentifier());
                }
            });
            hasAddToCart = true;
            $ctaWrap.append($addToCart);
        }
        const linkCta = sxQuery(`<a href="${suggest.getLink()}" class="ss360-mini-pdp__cta ss360-cta ss360-cta--button">${StringHelper.escapeHtml(pluginConfiguration.miniPDP.goToProductLabel)}</a>`);
        if (!hasAddToCart) {
            linkCta.addClass('ss360-cta--primary');
        }
        linkCta.on('click', () => {
            this.logAbandon = false;
            if (this.context.hasInsights()) {
                const cgItems = this.suggestItem.parent().find('.ss360-suggests:not(.ss360-suggests--hidden)').get();
                const layoutFrame = this.context.layer.get();
                const allItems = layoutFrame.getVisibleResults().get();
                const itemEl = this.suggestItem.get()[0];
                const contentGroupPosition = SxQueryUtils.indexInNodeList(itemEl, cgItems) + 1;
                const position = SxQueryUtils.indexInNodeList(itemEl, allItems) + 1;
                this.context.getInsights().trackSerpClick(this.query, itemEl, undefined, allItems.length, position, contentGroupPosition, this.suggest.getLink(), this.context.isSmart404() ? SearchResultType.Smart404 : this.context.getSearchResultType(), this.suggest.getIdentifier(), this.suggest.getIdentifier(), 'mini-pdp', this.suggest.contentGroup, this.suggest.getOriginalContentGroup());
            }
        });
        $ctaWrap.append(linkCta);
        return $ctaWrap;
    }
    _fillPriceContainer($priceContainer, priceDataPoint, oldPriceDataPoint, classBase = 'ss360-mini-pdp__price') {
        if (priceDataPoint === undefined) {
            $priceContainer.remove();
            return;
        }
        const priceAndCurrency = Helper.getPriceAndCurrency(priceDataPoint);
        $priceContainer.attr('itemprop', 'offers');
        $priceContainer.attr('itemscope', 'itemscope');
        $priceContainer.attr('itemtype', 'http://schema.org/Offer');
        $priceContainer.removeClass('ss360-shimmer');
        $priceContainer.attr('aria-busy', 'false');
        const $priceNode = sxQuery(`<span class="${classBase}">${priceDataPoint.value}</span>`);
        if (priceAndCurrency.price) {
            $priceNode.attr('itemprop', 'price');
            // @ts-ignore
            $priceNode.attr('content', priceAndCurrency.price);
        }
        if (priceAndCurrency.currency) {
            $priceNode.append(sxQuery(`<meta itemprop="priceCurrency" content="${priceAndCurrency.currency}">`));
        }
        $priceContainer.append($priceNode);
        if (oldPriceDataPoint !== undefined) {
            $priceContainer.append(`<span class="${classBase} ${classBase}--old">${oldPriceDataPoint.value}</span>`);
        }
    }
    _renderVariants() {
        const variants = this.suggest.getAllVariantImages(this.context.pluginConfiguration.results.variantGroupingAttribute);
        const $variantWrap = sxQuery('<ul class="ss360-mini-pdp__variants"></ul>');
        $variantWrap.attr('aria-label', this.context.pluginConfiguration.results.variantsLabel);
        const createScrollButton = (modifier, label) => {
            const $btn = sxQuery(`<button class="ss360-n-button ss360-mini-pdp__variant-scroll-button ss360-mini-pdp__variant-scroll-button--${modifier}" type="button"></button>`);
            $btn.append(Icons.getSvgIconNode(Icons.SIMPLE_ARROW, UiColors.THEME, 'ss360-mini-pdp__variant-scroll-button-icon', undefined, undefined, '0 0 24 24'));
            $btn.attr('aria-label', label);
            return $btn;
        };
        const $prev = createScrollButton('prev', 'Scroll left');
        const $prevLi = sxQuery('<li class="ss360-mini-pdp__variant-scroll-button-wrap ss360-mini-pdp__variant-scroll-button-wrap--prev" style="display:none" role="none"></li>');
        $prevLi.append($prev);
        $variantWrap.append($prevLi);
        variants.forEach((variant, idx) => {
            const $entry = sxQuery(`<li class="ss360-mini-pdp__variant${variant.identifier === this.suggest.getIdentifier() ? ' ss360-mini-pdp__variant--active' : ''}" role="none"></li>`);
            const $btn = sxQuery('<button class="ss360-n-button ss360-mini-pdp__variant-button" type="button"></button>');
            $btn.attr('aria-label', variant.name);
            $btn.attr('title', variant.name);
            $btn.attr('role', 'radio');
            $btn.attr('aria-checked', idx === 0 ? 'true' : 'false');
            const $img = sxQuery(`<img src="${variant.optimizedImage || variant.image}" alt="" class="ss360-mini-pdp__variant-image" loading="lazy" role="presentation">`);
            $btn.append($img);
            $btn.on('click', (e) => {
                const data = this.suggest.plain;
                const variantsOrder = data.map((s) => s.identifier);
                data.sort((a, b) => {
                    if (a.identifier === variant.identifier) {
                        return -1;
                    }
                    if (b.identifier === variant.identifier) {
                        return 1;
                    }
                    return 0;
                });
                const newSuggest = new SearchSuggest(data);
                newSuggest.setVariantsOrder(this.suggest.getVariantsOrder() || variantsOrder);
                const pdp = new MiniPDP(this.context, newSuggest, this.suggestItem, this.query, this.breadcrumb);
                pdp.lastVariantScrollLeft = this.lastVariantScrollLeft;
                pdp.show(false, true);
            });
            $entry.append($btn);
            $variantWrap.append($entry);
        });
        const $next = createScrollButton('next', 'Scroll right');
        const $nextLi = sxQuery('<li class="ss360-mini-pdp__variant-scroll-button-wrap ss360-mini-pdp__variant-scroll-button-wrap--next" style="display:none" role="none"></li>');
        $nextLi.append($next);
        $variantWrap.append($nextLi);
        SxQueryUtils.requestAnimationFrame(() => {
            const scrollMovement = 8 * $variantWrap.find('.ss360-mini-pdp__variant-button').outerWidth();
            const parentWidth = $variantWrap.outerWidth();
            const wrapScrollWidth = $variantWrap.get(0).scrollWidth;
            let isUpdating = false;
            const onScrollChanged = (scrollLeft) => {
                if (isUpdating)
                    return;
                this.lastVariantScrollLeft = scrollLeft;
                isUpdating = true;
                SxQueryUtils.requestAnimationFrame(() => {
                    if (scrollLeft === 0) {
                        $prevLi.hide();
                    }
                    else {
                        $prevLi.css('display', 'block');
                    }
                    if (scrollLeft + parentWidth + $nextLi.outerWidth() + $prevLi.outerWidth() >= wrapScrollWidth) {
                        $nextLi.hide();
                    }
                    else {
                        $nextLi.css('display', 'block');
                    }
                    isUpdating = false;
                });
            };
            if (this.lastVariantScrollLeft === undefined || this.lastVariantScrollLeft === 0) {
                onScrollChanged($variantWrap.get(0).scrollLeft);
            }
            else {
                $variantWrap.css('scroll-behavior', 'initial');
                $variantWrap.get(0).scrollLeft = this.lastVariantScrollLeft;
                setTimeout(() => {
                    $variantWrap.css('scroll-behavior', null);
                });
            }
            $variantWrap.on('scroll', () => {
                onScrollChanged($variantWrap.get(0).scrollLeft);
            });
            $prevLi.on('click', () => {
                $variantWrap.get(0).scrollLeft -= scrollMovement;
            });
            $nextLi.on('click', () => {
                $variantWrap.get(0).scrollLeft += scrollMovement;
            });
        });
        return $variantWrap;
    }
    _renderZoe() {
        const pluginConfiguration = this.context.pluginConfiguration;
        const suggest = this.suggest;
        const $zoeWrap = sxQuery('<div class="ss360-mini-pdp__description"></div>');
        const $contentWrap = sxQuery('<div class="ss360-mini-pdp__description-content"></div>');
        $contentWrap.append(Icons.getZoeIcon(undefined, undefined, 40, 34));
        let contextString = '{}';
        try {
            contextString = JSON.stringify(pluginConfiguration.zoe.context || {});
        }
        catch (err) {
            console.error(err);
        }
        const $zoe = sxQuery(`<zoovu-zoe sku="${suggest.getIdentifier()}" region="${pluginConfiguration.zoe.region || 'eu'}" locale="${pluginConfiguration.zoe.locale || 'en-US'}" mode="description"></zoovu-zoe>`);
        $zoe.attr('context', contextString);
        if (pluginConfiguration.searchSource === 'ZOE' && this.context.hasInsights()) {
            $zoe.attr('version', '2');
            $zoe.attr('communication-id', this.context.getInsights().getSessionId());
            $zoe.attr('app-key', pluginConfiguration.zoe.apiKey);
            $zoe.attr('mode', 'minipdp');
        }
        else {
            $zoe.attr('api-key', pluginConfiguration.zoe.apiKey);
        }
        if (pluginConfiguration.ecomDev) {
            $zoe.attr('dev', 'qa10');
        }
        $contentWrap.append($zoe);
        UiHelper.renderZoeScript(pluginConfiguration.zoe.region === ZoeApiConfigurationRegion.BARRACUDA, pluginConfiguration.ecomDev);
        $zoeWrap.append($contentWrap);
        return $zoeWrap;
    }
    _renderRelations(relationResults, $relationContainer, loadStartTime, includeTag, includeRelation) {
        const viewId = `${Math.round(Math.random() * 1000000)}`;
        const relationId = includeRelation ? `${includeRelation.id}` : undefined;
        const tag = includeTag ? includeTag.tag : undefined;
        $relationContainer.removeClass('ss360-mini-pdp__relations--loading ss360-shimmer');
        const $relationList = sxQuery('<div class="ss360-mini-pdp__relation-list"></div>');
        const resultsConfiguration = this.context.pluginConfiguration.results;
        relationResults.forEach((result, idx) => {
            const relationProduct = new SearchSuggest(result);
            const $relation = sxQuery('<article class="ss360-mini-pdp__relation-entry ss360-n-section" itemscope itemtype="http://schema.org/Product"></article>');
            $relation.append(`<meta itemprop="sku" content="${StringHelper.escapeHtml(relationProduct.getIdentifier())}">`);
            if (relationProduct.getLink()) {
                $relation.append(`<meta itemprop="url" content="${relationProduct.getLink()}">`);
            }
            if (relationProduct.getImage(true)) {
                $relation.append(`<a class="ss360-mini-pdp__relation-entry-image-wrap" href="${relationProduct.getLink()}" target="_blank"><img src="${relationProduct.getImage(true)}" alt="" class="ss360-mini-pdp__relation-entry-image" loading="lazy" itemprop="image"></a>`);
            }
            const contentContainer = sxQuery('<div class="ss360-mini-pdp__relation-entry-content"></div>');
            const priceDataPoint = Helper.getDataPoint(relationProduct, resultsConfiguration.priceDataPoint, 'newPrice');
            const oldPriceUnit = Helper.getDataPoint(relationProduct, resultsConfiguration.oldPriceDataPoint, 'oldPrice');
            if (priceDataPoint) {
                const $relPriceContainer = sxQuery('<div class="ss360-mini-pdp__relation-entry-price-container"></div>');
                contentContainer.append($relPriceContainer);
                this._fillPriceContainer($relPriceContainer, priceDataPoint, oldPriceUnit, 'ss360-mini-pdp__relation-entry-price');
            }
            contentContainer.append(`<h3 itemprop="name" class="ss360-mini-pdp__relation-entry-title"><a class="ss360-mini-pdp__relation-entry-link" href="${relationProduct.getLink()}" target="_blank">${StringHelper.escapeHtml(relationProduct.getName())}</a></h4>`);
            $relation.append(contentContainer);
            $relation.find('a').on('click', (e) => {
                this.logAbandon = false;
                if (this.context.pluginConfiguration.miniPDP.miniPDPForRelations) {
                    e.preventDefault();
                    e.stopPropagation();
                    const pdp = new MiniPDP(this.context, relationProduct, new SxQueryObject([]), this.query, [...this.breadcrumb, {
                            name: this.suggest.getName(),
                            suggest: this.suggest,
                            suggestItem: this.suggestItem
                        }]);
                    pdp.show(false, true);
                }
                if (this.context.hasInsights()) {
                    this.context.getInsights().trackRelatedContentClicked(this.suggest.getIdentifier(), viewId, relationProduct.getLink(), idx, relationId, tag, relationProduct.getIdentifier());
                }
            });
            $relationList.append($relation);
        });
        $relationContainer.append($relationList);
        if (this.context.hasInsights()) {
            this.context.getInsights().trackRelatedContentLoaded(this.suggest.getIdentifier(), (new Date()).getTime() - loadStartTime, relationResults.length, viewId, relationId, tag, this.query, true);
            $relationList.onScrolledIntoViewport(() => {
                this.context.getInsights().trackRelatedContentViewed(this.suggest.getIdentifier(), viewId, relationId, tag);
            });
        }
    }
    _renderBreadcrumb() {
        if (this.breadcrumb.length === 0 || !this.context.pluginConfiguration.miniPDP.renderBreadcrumb) {
            return undefined;
        }
        const breadcrumbParts = [...this.breadcrumb];
        const lastItem = breadcrumbParts[breadcrumbParts.length - 1];
        const isCurrentItemActive = lastItem.suggest ? lastItem.suggest.getIdentifier() === this.suggest.getIdentifier() : lastItem.name = this.suggest.getName();
        if (!isCurrentItemActive) {
            breadcrumbParts.push({
                name: this.suggest.getName(),
                suggest: this.suggest,
                suggestItem: this.suggestItem
            });
        }
        const item = breadcrumbParts[breadcrumbParts.length - 2];
        const btn = sxQuery(`<button type="button" class="ss360-n-button ss360-mini-pdp__breadcrumb">
                ${Icons.getSvgIcon(Icons.BACK, UiColors.GREY_TEXT, 'ss360-mini-pdp__breadcrumb-icon', 16, 16)}
                ${this.context.pluginConfiguration.miniPDP.backToLabel}&nbsp;
                <span class="ss360-mini-pdp__breadcrumb-name">${StringHelper.escapeHtml(item.name)}</span>
            </button>`);
        btn.on('click', () => {
            breadcrumbParts.splice(breadcrumbParts.length - 1);
            if (breadcrumbParts.length === 1) {
                breadcrumbParts.splice(0);
            }
            new MiniPDP(this.context, item.suggest, item.suggestItem || new SxQueryObject([]), this.query, breadcrumbParts).show(false, true);
        });
        return btn;
    }
    _renderReviews(reviews, $reviewsTabContent) {
        reviews.forEach((review) => {
            const $review = sxQuery('<article class="ss360-n-section ss360-mini-pdp__review" itemprop="review" itemscope itemtype="https://schema.org/Review"></article>');
            $review.append(UiHelper.renderRating({
                key: 'rating',
                parsedValue: review.rating,
                value: '',
                show: false
            }, undefined, this.context.pluginConfiguration.results.ratingCountLabel, 5, 'ss360-mini-pdp__review', false));
            if (review.title) {
                $review.append(`<h3 class="ss360-mini-pdp__review-title" itemprop="name">${StringHelper.escapeHtml(review.title)}</h3>`);
            }
            if (review.text) {
                $review.append(`<p class="ss360-mini-pdp__review-text" itemprop="reviewBody">${StringHelper.escapeHtml(review.text)}</p>`);
            }
            $review.append(`<meta itemprop="reviewRating" content="${review.rating}">`);
            const hasAuthor = review.author && review.author.length > 0;
            const hasCreationDate = review.creationDate && review.creationDate.time > 0;
            if (hasAuthor || hasCreationDate) {
                const $meta = sxQuery('<div class="ss360-mini-pdp__review-meta"></div>');
                if (hasAuthor) {
                    $meta.append(`<span class="ss360-mini-pdp__review-author" itemprop="author">${StringHelper.escapeHtml(review.author)}</span>`);
                }
                if (hasCreationDate) {
                    $meta.append(`<span class="ss360-mini-pdp__review-date" itemprop="datePublished" content="${new Date(review.creationDate.time).toISOString()}">${new Date(review.creationDate.time).toLocaleDateString()}</span>`);
                }
                $review.append($meta);
            }
            $reviewsTabContent.append($review);
        });
    }
    _renderBulletPoints(attributes) {
        const { bulletPoints } = this.context.pluginConfiguration.miniPDP;
        if (bulletPoints.length === 0) {
            return undefined;
        }
        const values = [];
        bulletPoints.forEach((bp) => {
            let value = Helper.getDataPoint(this.suggest, bp);
            if (value !== undefined) {
                values.push(value.value);
            }
            else {
                const attr = attributes.filter((a) => a.attribute === bp)[0];
                if (attr) {
                    values.push(attr.value);
                }
            }
        });
        if (values.length === 0) {
            return undefined;
        }
        const $bulletPoints = sxQuery('<ul class="ss360-mini-pdp__bullet-points"></ul>');
        values.forEach((value) => {
            $bulletPoints.append(`<li class="ss360-mini-pdp__bullet-point">${StringHelper.escapeHtml(value)}</li>`);
        });
        return $bulletPoints;
    }
    close(detach = true) {
        if (this.logAbandon && this.context.hasInsights()) {
            this.context.getInsights().trackMiniPDPAbandon(this.query, this.suggest.getLink(), this.suggest.getIdentifier(), this.suggest.getIdentifier(), this.suggest.contentGroup, this.suggest.getOriginalContentGroup());
            this.logAbandon = false;
        }
        sxQuery('body').removeClass('ss360-u-hide-y-overflow');
        sxQuery('body').off('keydown.miniPDP');
        sxQuery(window).off(`resize.miniPDP, orientationchange.miniPDP`);
        this.context.unsubscribe('close-mini-pdp', 'mini-pdp');
        if (detach) {
            NavigatorHelper.removeQueryParam(this.context.pluginConfiguration.results, this.queryParamName);
            if (this.backdrop !== undefined) {
                this.backdrop.addClass('ss360-mini-pdp__backdrop--hide');
            }
            this.wrap.addClass('ss360-mini-pdp--closing');
            if (this.context.pluginConfiguration.miniPDP.fullscreen) {
                this.wrap.css('transform', `matrix(1, 0, 0, 1, ${sxQuery('body').outerWidth()}, 0)`);
            }
            setTimeout(() => {
                this.wrap.removeClass('ss360-mini-pdp--open');
                this.context.layer.get().detachMiniPDP();
            }, 300);
        }
        else {
            if (this.backdrop !== undefined) {
                this.backdrop.remove();
            }
            this.wrap.remove();
        }
        this.suggestItem.find('.ss360-suggests__wrap').removeClass('ss360-suggests__wrap--mini-pdp-active');
        this.suggestItem.removeClass('ss360-suggests--mini-pdp-active');
        this.context.emitEvent('deselect-product', {
            product: this.suggest.suggest
        });
        lastOpenMiniPDP = undefined;
    }
}
export default MiniPDP;
export function closeAllMiniPDPs() {
    if (lastOpenMiniPDP) {
        lastOpenMiniPDP.close();
    }
}
export function isMiniPDPOpen() {
    return lastOpenMiniPDP !== undefined;
}
