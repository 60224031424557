import sxQuery from "../../sxQuery/sxQuery";
import FilterPosition from "../enums/FilterPosition";
import UiColors from "../styles/UiColors";
const getFirstVisibleResult = (layoutFrame) => {
    const results = layoutFrame.getVisibleResults();
    for (let i = 0; i < results.length; i++) {
        const result = results.get(i);
        const rect = result.getBoundingClientRect();
        if (rect.width > 0 && rect.height > 0) {
            return result;
        }
    }
    return undefined;
};
export default {
    init(layoutFrame, title, scrollNode) {
        const backToTop = sxQuery('<a href="#" class="ss360-back-to-top"></a>');
        backToTop.append(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.29289 6.23039C7.90237 6.62092 7.90237 7.25408 8.29289 7.64461C8.68342 8.03513 9.31658 8.03513 9.70711 7.64461L11.0625 6.28921V20C11.0625 20.5523 11.5102 21 12.0625 21C12.6148 21 13.0625 20.5523 13.0625 20V6.53921L14.1679 7.64461C14.5584 8.03513 15.1916 8.03513 15.5821 7.64461C15.9726 7.25408 15.9726 6.62092 15.5821 6.23039L12.6446 3.29289C12.2541 2.90237 11.6209 2.90237 11.2304 3.29289L8.29289 6.23039Z" fill="${UiColors.WHITE}"/></svg>`);
        backToTop.attr('title', title);
        backToTop.on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            let targetElement = (layoutFrame.getActiveNavItem().parent().get(0)) || layoutFrame.getFilterLayer(FilterPosition.Top).find('.ss360-filter__group').get(0) || getFirstVisibleResult(layoutFrame);
            if (targetElement !== undefined) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            else {
                sxQuery(window).scrollTop(0);
            }
        });
        let isHandlerActive = false;
        let isVisible = false;
        const handler = () => {
            if (isHandlerActive)
                return;
            isHandlerActive = true;
            setTimeout(() => {
                const firstVisibleResult = getFirstVisibleResult(layoutFrame);
                let newIsVisible = false;
                if (firstVisibleResult !== undefined) {
                    const rect = firstVisibleResult.getBoundingClientRect();
                    newIsVisible = (rect.top + rect.height) < 0;
                }
                if (newIsVisible && !isVisible) {
                    backToTop.addClass('ss360-back-to-top--active');
                    isVisible = true;
                }
                else if (!newIsVisible && isVisible) {
                    backToTop.removeClass('ss360-back-to-top--active');
                    isVisible = false;
                }
                isHandlerActive = false;
            }, 50);
        };
        sxQuery(scrollNode || window).off('scroll.backToTop').on('scroll.backToTop', undefined, handler, { passive: true });
        layoutFrame.detachBackToTop();
        layoutFrame.appendToBackToTopFrame(backToTop);
        handler();
    }
};
