'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import FilterPosition from '../enums/FilterPosition';
import Icons from './Icons';
import UiColors from '../styles/UiColors';
import Helper from '../utils/Helper';
const Sorting = {
    render(layoutFrame, sortingOptions, appliedSorting, callback, selectedText, activeFilterOptions, activeGuidedQuestions, pluginConfig) {
        const filtersConfiguration = pluginConfig.filters;
        let isLeft = filtersConfiguration.enabled && (filtersConfiguration.position === FilterPosition.Top || (filtersConfiguration.position === FilterPosition.Left && filtersConfiguration.forceSlideIn && !pluginConfig.layout.showListGridToggle));
        if (filtersConfiguration.enabled && filtersConfiguration.position === FilterPosition.Left && filtersConfiguration.highlightPopularFilters && !pluginConfig.layout.showListGridToggle) {
            isLeft = true;
        }
        const $select = sxQuery(`<select class="ss360-sorting__select">`);
        $select.attr('aria-label', pluginConfig.results.sortingLabel);
        const selectIconSvg = Icons.getSvgIconNode(Icons.SIMPLE_ARROW, UiColors.THEME, undefined, 16, 16);
        const selectIconPlain = document.createElement('i');
        selectIconPlain.className = 'ss360-sorting__select-icon';
        selectIconPlain.appendChild(selectIconSvg);
        const selectIcon = sxQuery(selectIconPlain);
        const sortIconSvg = Icons.getSvgIconNode(Icons.UP_DOWN_ARROWS, UiColors.THEME, undefined, 16, 16);
        const sortIconPlain = document.createElement('i');
        sortIconPlain.className = 'ss360-sorting__sort-icon';
        sortIconPlain.appendChild(sortIconSvg);
        const sortIcon = sxQuery(sortIconPlain);
        layoutFrame.appendToSortingFrame(sortIcon, isLeft);
        layoutFrame.appendToSortingFrame($select, isLeft);
        layoutFrame.appendToSortingFrame(selectIcon, isLeft);
        const relevanceLabel = pluginConfig.results.orderByRelevanceText || 'Relevance';
        $select.append(sxQuery(`<option value="_relevance">${relevanceLabel}</option>`));
        const keyCountMap = sortingOptions === undefined ? [] : sortingOptions.reduce((acc, so) => {
            if ((typeof so) === 'string') {
                // @ts-ignore
                acc[so] = 1;
            }
            else {
                // @ts-ignore
                const soObject = so;
                let key = soObject.key || '';
                if (soObject.sort !== undefined) {
                    key = `${key}_${soObject.sort}`;
                }
                if (acc[key] === undefined) {
                    acc[key] = 0;
                }
                acc[key]++;
            }
            return acc;
        }, {});
        const appendName = Object.keys(keyCountMap).reduce((acc, key) => acc || keyCountMap[key] > 1, false);
        for (let i = 0; i < sortingOptions.length; i++) {
            const sortingOption = sortingOptions[i];
            let option;
            if ((typeof sortingOption) === 'string') {
                option = sxQuery(`<option value="${sortingOption}">${sortingOption}</option>`);
            }
            else {
                // @ts-ignore
                const so = sortingOption;
                let key = so.key;
                if (so.sort !== undefined) {
                    key = `${key}_${so.sort}`;
                }
                if (appendName && so.name !== undefined) {
                    key = `${key}_${so.name}`;
                }
                option = sxQuery(`<option value="${key}">${so.name}</option>`);
            }
            $select.append(option);
        }
        // @ts-ignore
        const sortingOptionsMap = Helper.getSortingOptionMap(sortingOptions);
        let val = appliedSorting || '_relevance';
        if ((typeof val) !== 'string') {
            // @ts-ignore
            const so = val || {};
            let direction = so.sort;
            if (direction === undefined) {
                direction = so.direction;
            }
            val = so.key;
            if (val && direction !== undefined) {
                val = `${val}_${direction}`;
            }
            if (appendName && so.name !== undefined) {
                val = `${val}_${so.name}`;
            }
        }
        if (val && (typeof val) === 'string' && sortingOptionsMap[val] === undefined && (typeof appliedSorting) !== 'string' && appliedSorting.name
            && `${appliedSorting.key}` !== `${appliedSorting.name}`) {
            sortingOptionsMap[val] = {
                key: val,
                sort: appliedSorting.direction || appliedSorting.sort,
            };
            $select.append(sxQuery(`<option value="${val}">${appliedSorting.name}</option>`));
        }
        if (!val || sortingOptionsMap[val] === undefined) {
            val = '_relevance';
        }
        // @ts-ignore
        $select.val(val);
        $select.on('change', () => {
            // @ts-ignore
            let sort = $select.val();
            if (sort !== '_relevance') {
                // @ts-ignore
                sort = sortingOptionsMap[sort];
            }
            if ((typeof sort) !== 'string') {
                // @ts-ignore
                const so = sort;
                if (pluginConfig.results.nameParsing && so.name !== undefined) {
                    sort = so.name;
                }
                else {
                    sort = JSON.stringify(sort);
                }
            }
            callback(selectedText, {
                // @ts-ignore
                sort,
                shouldPushState: true,
                searchButton: $select.get()[0],
                submitSource: 'order',
                filters: activeFilterOptions,
                activeGuidedQuestions
            });
        });
    }
};
export default Sorting;
